// src/components/ResourceListingLayout.js

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import ResourcesHeader from './ResourcesHeader.js'
import ResourcesFilter from './ResourcesFilter.js'
import TagsFilter from './TagsFilter.js'
import ResourcesList from './ResourcesList.js'
import Pagination from './Pagination.js'

function ResourceListingLayout(props) {
  const data = useStaticQuery(graphql`
    query ResourceIntroQuery {
      wpPage(template: { templateName: { eq: "Resources Listing" } }) {
        seo {
          ...SeoFragment
        }
        title
        template {
          ... on WpTemplate_ResourcesListing {
            resourcesPage {
              pageDescription
            }
          }
        }
      }
    }
  `)

  const introData = data.wpPage

  return (
    <>
      <Seo post={introData} />

      <ResourcesHeader
        title={introData.title}
        description={introData.template.resourcesPage.pageDescription}
      />

      <div className="mx-auto max-w-[97rem] px-responsive-xl-32 lg:px-responsive-xl-64">
        <div className="grid gap-responsive-xl-128 md:grid-cols-sidebar md:gap-responsive-xl-64">
          <div className="sidebar flex flex-wrap items-center md:ml-0 md:block md:border-r md:border-granite-200 md:pl-responsive-xl-24 md:pr-responsive-xl-32 md:text-left">
            <span className="mr-responsive-xl-4 mb-responsive-xl-16 font-bold text-meta-md uppercase leading-relaxed tracking-widest text-granite-600 md:hidden">
              Filter by:
            </span>

            <ResourcesFilter
              section={props.resourceType}
              resourceFilters={props.resourceFilters}
            />

            <hr className="my-responsive-xl-32 hidden border-granite-200 md:block" />

            <TagsFilter
              postType={props.resourceType}
              tagsList={props.tagsList}
              currentTag={''}
              partiallyActive={true}
            />
          </div>
          <div className="results space-y-responsive-xl-32">
            <ResourcesList resources={props.resourcesList} />

            <Pagination
              currentPage={props.currentPage}
              numPages={props.numPages}
              pageSlug={`resources/${props.resourceType}`}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ResourceListingLayout
